@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Inter:700|Poppins:wght@200;300;400;500;600;700|Nunito:ital,wght@0,100..700;1,100..700&display=swap");

.inverted {
  filter: invert(1);
}

body,
html {
  height: 100%;
  font-family: "Nunito";
  font-optical-sizing: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* GroupPool */

.tokenSelectionContainer {
  /* background-color: aqua; */
  flex-direction: row;
  display: flex;
  gap: 10vw;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.tokenSelectionInput {
  background-color: azure;
  text-align: center;
}

.gpinfo {
  justify-self: center;
  margin-left: 20vw;
}

.button1 {
  min-width: 100px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}

.warn {
  background-color: coral;
}

.selection {
  /* background-color: green; */
  /* background-color: azure; */
  background-color: rgb(0, 60, 60);
  /* background-color:  rgb(0 128 255); */
}

.pool {
  background-color:  rgb(255 217 0);
}

.proceed {
  /* background-color: green; */
  /* background-color: azure; */
  /* background-color: green; */
  background-color:  rgb(0 128 255);
}

.delayed {
  background-color: grey;
}

.form-row {
  /* display: flex; */
  /* flex-direction: row; */
  width: 30vw;
}

/* .form-row */

.creation-form {
  display: grid;
  grid-template-columns: 30vw 30vw;
  grid-template-rows: repeat(5, 1fr) 3vw;
  row-gap: 1.5vh;
  border-radius: 12px;
  border-width: 2px;
  border-color: ivory;
  border-style: solid;
}

.creation-submit-wrapper {
  /* margin-left: 100px; */
  /* margin-right: 100px; */
  margin: auto;
}

.creation-input-box {
  /* background-color: indigo; */
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  width: auto;
  justify-content: space-evenly;
}

.creation-input {
  /* @apply bg-slate-500; */
  background-color: rgb(0, 60, 60);
  color: white;
  @apply px-2;
  border-color: transparent;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  min-width: 14vw;
}
/* 
.creation-input-1 {
  @apply px-2
} */

.row {
  margin-left: 0.1vw;
  /* display: grid;
  grid-template-columns: 200px 200px 200px */
  /* flex-direction: row; */
}

.rows {
  display: flex;
  flex-direction: row;
}

:root {
  --white: rgba(255,255,255,1);
  --black: rgba(0,0,0,0.698);
  --dodger-blue: rgba(51, 153, 255, 1);

  --font-size-m: 16px;
  --font-size-l: 20px;
  --font-size-xl: 32px;
  --font-size-xxl: 40px;
}

.optivaultContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 930px;
  margin-top: 80px;

  font-family: 'Roboto';
  font-style: normal;
}

.titleBar {
  display: flex;
  flex-direction: column;
  gap: 18px;

  color: var(--white);
  white-space: nowrap;
  width: 630px;
  letter-spacing: 0;
}

.titleBarHeader {
  font-size: var(--font-size-xxl);
  font-weight: 700;
  line-height: 54px;
}

.titleBarText {
  font-size: var(--font-size-l);
  font-weight: 400;
  line-height: 28px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.cardBalance {
  background-color: var(--black);
  border-radius: 20px;
  display: flex;
  gap: 24px;
  padding: 16px 24px;
  width: 930px;
  color: var(--white);
}

.balanceLabel {
  color: var(--dodger-blue);
  font-size: var(--font-size-m)
}

.cardVault {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
}

.vaultHead {
  background-color: black;
  border-radius: 20px 20px 0px 0px;
  height: 108px;
  min-width: 930px;
  position: relative;
}

.campaign {
  display: flex;
  gap: 12px;
  left: 32px;
  position: absolute;
  top: 32px;
  width: fit-content;
}

.logo-hoge {
  height: 36px;
  min-width: 36px;
  object-fit: cover;
}

.campaignName {
  letter-spacing: 0;
  line-height: 36px;
  margin-top: -1px;
  width: fit-content;
  color: var(--white);
  font-size: var(--font-size-xl);
  font-weight: 700;
  overflow-wrap: break-word;
}

.lockStatus {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
  top: 32px;
  width: fit-content;
  color: var(--white);
  margin-left: auto;
  margin-right: 30px;
}

.badgeLockStatus {
  height: 45px;
}

.vaultTail {
  align-items: flex-start;
  background-color: var(--black);
  border-radius: 0px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  position: relative;
  width: 930px;
}

.progressIndicator {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 12px;
}

.progressBar {
  background-color: var(--green);
  border-radius: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 4px 32px;
}

.vaultData {
  color: var(--white);
  height: 80px;
  min-width: 866px;
  position: relative;
}

.lpData {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: fit-content;
}

.theThinGrayLine {
  display: none;
  width: 295px;
}

.userData {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 687px;
  position: absolute;
  top: 0px;
  gap: 4px;
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .optivaultContent {
    gap: 48px;
    width: 335px;
    margin-top: unset;
  }
  .titleBar {
    width: 335px;
    gap: 12px;
  }
  .titleBarHeader {
    font-size: 28px;
    line-height: 135%;
  }
  .titleBarText {
    width: 335px;
    font-size: 18px;
    line-height: 24px;
    white-space: normal;
  }
  .cards {
    gap: 24px;
  }
  .cardBalance {
    width: 335px;
    flex-direction: column;
    gap: 4px;
  }
  .vaultHead {
    width: 335px;
    min-width: 335px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 152px;
    padding: 24px 20px;
  }
  .campaign {
    position: relative;
    left: unset;
    top: unset;
  }
  .campaignName {
    font-size: 24px;
    line-height: 28px;
    white-space: unset;
  }
  .lockStatus {
    margin-left: unset;
    margin-right: unset;
    flex-direction: row-reverse;
    top: unset;
  }
  .unlockMsg {
    width: 186px;
    text-align: right;
  }
  .vaultTail {
    width: 335px;
    height: 301px;
    padding: 24px 20px;
  }
  .vaultTailMin {
    height: 172px;
  }
  .vaultData {
    min-width: unset;
    width: 295px;
    height: 209px;
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
  .vaultDataMin {
    height: 80px;
  }
  .theThinGrayLine {
    display: block;
  }
  .userData {
    left: unset;
    position: relative;
    align-items: flex-start;
  }
}